<template>
  <div class="container">
    <div v-for="(item, index) in dataList" :key="index" class="marginB">
      <h1 v-if="index === 0" class="top_title">{{dataList[0]["topTitle"]}}</h1>
      <p class="h1">{{ item.title }}</p>
      <p v-for="(citem, cindex) in item.content" :key="cindex">
        {{ citem }}
      </p>
    </div>
  </div>
</template>

<script>
import { privacyData } from "../data/privacyData";

export default {
  name: "Privacy",
  data() {
    return {
      dataList: [],
    };
  },
  methods: {
    fetchData(initLang) {
      this.dataList = privacyData.cn;
       if (initLang === "en") {
            this.dataList = privacyData.en;
          }
        },
  },
  mounted() {
    let lang = this.$route.params.lang;
    this.fetchData(lang);
  },
};
</script>

<style scoped lang="less">
.container {
  color: #333;
  padding: 16px;
  font-size: 16px;
  .top_title{
    text-align: center;
    font-size: 4.53vw;
    font-weight: bold;
    margin-bottom: 6.4vw;
    color: #121212;
  }
  .h1 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  p {
    font-size: 14px;
    line-height: 1.8;
    text-align: justify;
    text-align-last: left;
    margin-bottom: 8px;
  }

  .marginB {
    margin-bottom: 12px;
  }
}
</style>
