let appName = '手机云管家';
var u = navigator.userAgent;
var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
if (isiOS) {
    appName = '手机云管家';
} else if (isAndroid) {
    appName = '手机云管家';
}

const privacyData = {
    'cn': [
        {
          "topTitle":"隐私政策"
        },
        {
           "content": [
               `本隐私政策将帮助你了解：`,
               `（1）为了保障产品的正常运行，提供新颖、轻松的拍照和互动交流等核心功能以及其他功能，我们会收集你的部分必要信息；`,
               `（2）我们仅会在为提供“手机云管家”软件及相关服务之目的所必需的期间内保留你的个人信息；`,
               `（3）我们不会向第三方共享、提供、转让或者从第三方获取你的个人信息，除非经过你的同意；`,
               `（4）我们将努力采取合理的安全措施来保护你的个人信息。特别的，我们将采用行业内通行的方式及尽最大的商业努力来保护你个人敏感信息的安全；`,
               `（5）你访问、更正、删除个人信息与撤回同意授权的方式，以及注销账号、投诉举报的方式。`,
               `我们非常重视用户个人信息的保护，并且将以勤勉和审慎的义务对待这些信息。你在下载、安装、开启、浏览、注册、登录、使用（以下统称“使用”）“手机云管家”软件及相关服务时，我们将按照本《隐私政策》收集、保存、使用、共享、披露及保护你的个人信息。我们希望通过本《隐私政策》向你介绍我们对你个人信息的处理方式，因此我们建议你认真完整地阅读本《隐私政策》的所有条款。其中，与你信息及权利相关的内容将以加粗的形式提示你注意，请你重点阅读。`,
               `本隐私政策旨在帮助你了解以下内容：`,
               `一、我们如何收集和使用个人信息`,
               `二、我们对Cookie 和同类技术的使用`,
               `三、我们如何存储个人信息`,
               `四、我们如何共享、转让、公开披露个人信息`,
               `五、我们如何保护个人信息的安全`,
               `六、你的权利`,
               `七、未成年人使用条款`,
               `八、隐私政策的修订和通知`,
               `九、适用范围`,
               `十、其他`,
           ]
        },
        {
            'title': '一、我们如何收集和使用个人信息',
            "content": [
                `在你使用“手机云管家”软件及服务的过程中，我们将根据合法、正当、必要的原则，收集信息。我们收集或请你提供的信息将用于：`,
                `1.保障产品的基础正常运行；`,
                `2.实现各项功能和服务；`,
                `3.优化、改善产品和服务；`,
                `4.保障产品、服务以及用户使用安全；`,
                `5.遵循法律法规与国家标准的规定。`,
                `（一）我们主动收集与使用的个人信息`,
                `我们会按照如下方式收集你在使用服务时主动提供的，以及你在使用功能或接受服务过程中产生的信息：`,
                `1. 保障“手机云管家”软件和相关服务的正常运行`,
                `当你使用“手机云管家”及相关服务时，为了保障软件与服务的正常运行，我们会收集你的硬件型号、操作系统版本号、国际移动设备识别码（IMEI）、网络设备硬件地址（MAC）、IP地址、软件版本号、网络接入方式及类型、操作日志等信息。请你了解，这些信息是我们提供服务和保障产品正常运行所必须收集的基本信息。`,
                `2. 登录“手机云管家”及相关服务`,
                `你可以使用第三方账号授权登录手机云管家，你此时将授权我们获取你在第三方平台注册的公开信息（如头像、昵称等），并在你同意本隐私政策后将你的第三方账号与你的手机云管家账户绑定。`,
                `3. 为你相册清理，通讯录管理，云相册等功能`,
                `云相册，一键优化是“手机云管家”核心的功能之一。为实现这一功能，我们会请求你授权相册、等敏感权限。你如果拒绝授权提供某些服务必要权限，将无法使用相关的功能，但不影响你正常使用“手机云管家”的其他功能。`,
                `请你了解，我们收集、使用上述信息时进行了去标识化处理/匿名化处理，数据分析仅对应特定的、无法直接关联用户身份的编码。`,
                `6.保障产品、服务及用户使用安全`,
                `为帮助我们更好地了解“手机云管家”及相关服务的运行情况，以便确保运行与提供服务的安全，我们可能记录网络日志信息，以及使用软件及相关服务的频率、崩溃数据、总体安装、使用情况、性能数据等信息。`,
                `7.开展营销活动`,
                `当你选择参加我们举办的有关营销活动时，根据活动需要你可提供姓名、通信地址、联系方式、银行账号等信息。这些信息是个人敏感信息，是你收到转账或者礼品所必要的，如果你拒绝提供这些信息，我们将无法向你转账或发放礼品。`,
                `（二）收集、使用个人信息目的变更的处理`,
                `请你了解，随着我们业务的发展，可能会对“手机云管家”的功能和提供的服务有所调整变化。在与原目的无直接或合理关联的场景下，我们收集、使用你的个人信息，会再次进行告知，并征得你的同意。`,
                `（三）依法豁免征得同意收集和使用的个人信息`,
                `请你理解，在下列情形中，根据法律法规和/或相关国家标准，我们收集和使用你的个人信息无需征得你的授权同意：`,
                `（1）与国家安全、国防安全直接相关的；`,
                `（2）与公共安全、公共卫生、重大公共利益直接相关的；`,
                `（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；`,
                `（4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的`,
                `（5）所收集的你的个人信息是你自行向社会公众公开的；`,
                `（6）从合法公开披露的信息中收集的你的个人信息的，如合法的新闻报道、政府信息公开等渠道；`,
                `（7）根据你的要求签订和履行合同所必需的；`,
                `（8）用于维护“手机云管家”软件及相关服务的安全稳定运行所必需的，例如发现、处置“手机云管家”软件及相关服务的故障；`,
                `（9）法律法规规定的其他情形。`,
                `特别提示你注意，如信息无法单独或结合其他信息识别到你的个人身份，其不属于法律意义上你的个人信息；当你的信息可以单独或结合其他信息识别到你的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他你的个人信息结合使用时，则在结合使用期间，这些信息将作为你的个人信息按照本隐私政策处理与保护。`,
            ]
        },
        {
            "title": "二、对Cookie 和同类技术的使用",
            "content":[
                'Cookie 和同类技术是互联网中的通用常用技术。当你使用“手机云管家”软件及相关服务时，我们可能会使用相关技术向你的设备发送一个或多个Cookie 或匿名标识符，以收集和存储你访问、使用本产品时的信息。我们使用Cookie 和同类技术主要为了实现以下功能或服务：',
                '（一）保障产品与服务的安全、高效运转',
                '我们可能会设置认证与保障安全性的cookie 或匿名标识符，使我们确认你是否安全登录服务，或者是否遇到盗用、欺诈等不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。',
            ]

        },
        {
            "title": "三、我们如何存储个人信息",
            "content":[
                '（一）信息存储的地点',
                '我们依照法律法规的规定，将在境内运营过程中收集和产生的你的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将会遵循法律法规的规定，征求你的明示同意。',
                '（二）存储期限',
                '我们仅在为提供“手机云管家”及服务之目的所必需的期间内保留你的个人信息，例如，你的账号信息，在你未撤回、删除或未注销账号期间，我们会保留相关信息。超出必要期限后，我们将对你的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。',
            ]

        },
        {
            "title": "四、我们如何共享、转让、公开披露个人信息",
            "content":[
                '（一）个人信息的共享、转让与第三方收集',
                '我们不会向第三方共享、转让你的个人信息，除非经过你本人事先授权同意，或者共享、转让的个人信息是匿名化处理后的信息，且第三方无法根据此类信息重新识别自然人主体。',
                '1. 为实现相关功能或服务与关联方共享',
                '当你在使用“手机云管家”及关联方相关服务提供的内容同步功能时，你在我们及关联方提供的产品间所接受的服务具有一致性。为方便统一管理你的信息，经过你的明示同意，我们可能会将你的个人信息与这些关联方共享。',
                '当你选择通过特定第三方账号授权登录，并使用第三方所提供的相关功能时，功能提供方将遵照其用户协议与隐私政策，经你同意后收集和处理为实现这些功能所必需的个人信息，包括但不限于：',
                '（1）你可以通过采用第三方账号授权登录的方式，使用第三方提供的相关功能服务。为实现这一功能，服务提供方会使用经你授权在第三方账号内的相关信息（账号、头像、昵称等）。这些信息是实现相关功能的必要信息，可能包含个人敏感信息，拒绝提供该等信息不会影响你正常使用“手机云管家”的其他功能。',
                '（2）当你使用第三方服务提供方与第三方支付公司合作提供的钱包支付功能服务，第三方支付公司需要收集你的姓名、银行卡类型及卡号、有效期及手机号码等信息。上述信息属于个人敏感信息，拒绝提供该等信息仅会使你无法使用该功能，但不影响你正常使用其他功能。',
                '2. 为实现特定功能而与业务合作伙伴共享',
                '当软件服务提供商、智能设备提供商或系统服务提供商与我们联合为你提供服务时，我们会与第三方共享为实现功能所必需的数据。',
                '当你需要使用地理位置功能时，为实现这一功能，我们可能会收集你的位置信息及相关设备信息（例如硬件型号、操作系统版本号、国际移动设备身份识别码（IMEI）、网络设备硬件地址（MAC））经过去标识化后并提供给前述提供商。你可以拒绝提供此类信息，但不影响你使用其他服务。',
                '3．帮助你参加调研营销活动',
                '当你选择参加我们举办的有关调研活动时，我们可能根据活动需要委托第三方调研机构收集你的姓名、通信地址、联系方式等信息。我们将要求此等第三方调研机构以不低于本隐私政策的保护水平确保你的个人信息的安全，并在未经你事先允许的情况下，不得将你的个人信息用于任何其他目的或用途。如果你拒绝提供此等信息，我们将可能无法向你提供礼品。',
                '4.对共享个人信息第三方主体的谨慎评估及责任约束',
                '（1）经你同意，我们只会与第三方共享实现目的所必要的信息。如果第三方因业务需要，确需超出前述授权范围使用个人信息的，该第三方将需再次征求你的自主选择同意。',
                '（2）对我们与之共享你个人信息的第三方，该些第三方会与我们签订保密协议。同时，我们会对其数据安全能力与环境进行评估并要求第三方以不低于本隐私政策所要求的保密和安全措施来处理该些信息。',
                '5.收购、兼并、重组时个人信息的转让',
                '随着我们业务的持续发展，我们将有可能进行合并、收购、资产转让，你的个人信息有可能因此而被转移。在发生前述变更时，我们将按照法律法规及不低于本隐私政策所要求的安全标准继续保护或要求个人信息的继受方继续保护你的个人信息，否则我们将要求继受方重新征得你的授权同意。',
                '（二）个人信息的公开披露',
                '除对违规账号、欺诈行为进行处罚公告时，我们会披露相关账号的必要信息外，我们不会公开披露你的信息，除非遵循国家法律法规规定或者获得你的自主选择同意。我们公开披露你的个人信息会采用符合行业内标准的安全保护措施。',
                '（三）依法豁免征得同意共享、转让、公开披露的个人信息',
                '请你理解，在下列情形中，根据法律法规和/或相关国家标准，我们共享、转让、公开披露你的个人信息无需征得你的授权同意',
                '（1）与国家安全、国防安全直接相关的；',
                '（2）与公共安全、公共卫生、重大公共利益直接相关的；',
                '（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；',
                '（4）出于维护你或其他个人的生命、财产等重大合法权益但又很难得到你本人同意的',
                '（5）你自行向社会公众公开的个人信息；',
                '（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。',
            ]

        },{
            "title": "五、第三方SDK",
            "content":[
                '我们使用了第三方SDK：友盟SDK。用于采集你使用我们的产品时的操作行为，采集这些数据我们仅用于统计分析，避免崩溃错误等情况出现，以便我们能及时修复这些错误。该SDK不会对外披露你的任何操作行为，也绝不会收集你的任何个人信息。',
            ]

        },
        {
            "title": "六、我们如何保护个人信息安全",
            "content":[
                '（一）我们非常重视你个人信息的安全，将努力采取合理的安全措施（包括技术方面和管理方面）来保护你的个人信息，防止你提供的个人信息被不当使用或未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄漏。',
                '（二）我们会使用不低于行业同行的加密技术、匿名化处理等合理可行的手段保护你的个人信息，并使用安全保护机制防止你的个人信息遭到恶意攻击。',
                '（三）我们会建立专门的安全部门、安全管理制度、数据安全流程保障你的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问你的个人信息，并适时对数据和技术进行安全审计。',
                '（四）尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请你理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保你提供给我们的个人信息的安全性。你知悉并理解，你接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议你采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码等个人信息透露给他人。',
                '（五）我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止该等安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向你告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、你可自主防范和降低风险的建议、对你的补救措施等。我们将及时将事件相关情况以推送通知、邮件、信函、短信等形式告知你，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。',
                '（六）我们谨此特别提醒你，本隐私政策提供的个人信息保护措施仅适用于“手机云管家”软件及相关服务。一旦你离开“手机云管家”及相关服务，浏览或使用其他网站、服务及内容资源，我们即没有能力及义务保护你在“手机云管家”软件及相关服务之外的软件、网站提交的任何个人信息，无论你登录、浏览或使用上述软件、网站是否基于“手机云管家”的链接或引导。',
            ]

        },
        {
            "title": "七、你的权利",
            "content":[
                '我们非常重视你对个人信息的管理，并尽全力保护你对于你个人信息的访问、修改（更新或更正）、删除以及撤回授权同意的权利，以使你拥有充分的能力保障你的隐私和安全。',
                '（一）管理、撤回授权你的信息及营销信息',
                '1.改变或撤回授权你的信息',
                '你可以通过取消登录状态、关闭功能、在客户端设置中更改应用程序权限等方式撤回你的同意，改变你授权我们继续收集个人信息的范围或撤回你的授权。请你理解，特定的业务功能和服务将需要你的信息才能得以完成，当你撤回同意或授权后，我们无法继续为你提供撤回同意或授权所对应的功能和服务，也不再处理你相应的个人信息。但你撤回同意或授权的决定，不会影响此前基于你的授权而开展的个人信息处理。',
                '如果你认为我们违反法律法规规定或与你的约定收集、使用你的个人信息，你也可以通过电子邮件与我们取得联系，并要求删除你的相关个人信息。我们将尽快确认所涉问题，并由专人验证你的用户身份后及时予以回复与处理。',
                '2.拒绝接受推送及营销信息',
                '你可以通过等退订我们对你的推送或发送给你的营销信息。',
                '（一） 注销你的账号',
                '如您需要注销您的"手机云管家"账号，请请立即通过邮箱（cleanphone.feedback@gmail.com）联系官方运营，我们将会10个工作日内提供您注销账号服务。在您注销账号前，我们将验证你的个人身份、安全状态、设备信息等。您知悉并理解，注销账号的行为是不可逆的行为，一旦你注销账号，我们将停止为你提供相关服务。',
                '（二）投诉举报,',
                `在“手机云管家”软件及相关服务的某些业务功能中，我们可能依据信息系统、算法等在内的非人工自动决策机制作出决定，如果这些决定显著影响你的合法权益，你有权要求我们作出解释，我们也将提供适当的救济方式。`,
                `对于你上述合理的请求，我们原则上不会收取任何费用。但对多次重复、超出合理限度的请求，我们将视实际情况收取一定成本费用。对于无端重复、需要过多技术手段（如需要开发新系统或从根本上改变现行管理）、给他人合法权益带来风险或非常不切实际的请求，我们可能予以拒绝。`,
                `（三）访问隐私政策`,
                `你可以在客户端“我的”-“隐私政策”查看本隐私政策的全部内容`,
                `（四）停止运营向你告知权利`,
                `如我们停止运营，我们将及时停止收集你个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知你，并对所持有的你的个人信息进行删除或匿名化处理。`,
            ]

        },
        {
            "title": "八、未成年人条款",
            "content":[
                '若你是未满18 周岁的未成年人，在使用“手机云管家”软件及相关服务前，应在你的父母或其他监护人监护、指导下共同阅读并同意本隐私政策。',
                '我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用、共享或披露未成年人的个人信息；如果我们发现在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关信息。',
                '若你是未成年人的监护人，当你对你所监护的未成年人的个人信息有相关疑问时，请通过本隐私政策公示的联系方式与我们联系。',
            ]

        },
        {
            "title": "九、隐私政策的修订和通知",
            "content":[
                '（一）为了给你提供更好的服务，“手机云管家”软件及相关服务将不时更新与变化，我们会适时对本隐私政策进行修订，该等修订构成本隐私政策的一部分并具有等同于本隐私政策的效力。但未经你明确同意，我们不会削减你依据当前生效的本隐私政策所应享受的权利。',
                '（二）本隐私政策更新后，我们会在“手机云管家”应用程序客户端和官方网站发出更新版本，并在更新后的条款生效前以适当的方式提醒你更新的内容，以便你及时了解本隐私政策的最新版本。如你继续使用我们的服务，表示同意接受修订后的本政策的内容，但是如果更新的内容需要采集音视频、联系方式、地理位置等个人敏感信息，仍会再次以显著方式征求你的同意。',
                '（三）对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信、私信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。',
                '本隐私政策所指的重大变更包括但不限于：',
                '1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息的类型、个人信息的使用方式等；',
                '2.我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有权变更等；',
                '3.个人信息共享、转让或公开披露的主要对象发生变化；',
                '4.你参与个人信息处理方面的权利及其行使方式发生重大变化；',
                '5.我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时。',
            ]

        },
        {
            "title": "十、适用范围",
            "content":[
                '本隐私权政策适用于本公司及其关联方提供的所有服务，包括“手机云管家”客户端、网站等，不适用于有单独的隐私权政策且未纳入本隐私权政策的第三方产品或服务。',
                '本隐私权政策不适用于：',
                '1.其他第三方产品或服务，可能包括在个性化推荐中向你显示的产品或网站和广告内容或者“手机云管家”服务中链接到的其他产品或网站；',
                '2.为“手机云管家”服务进行广告宣传的其他第三方。',
                '你使用这些第三方服务（包括你向这些第三方提供的任何个人信息），将受这些第三方的服务条款及隐私政策约束（而非本隐私政策），具体规定请你仔细阅读第三方的条款。请你妥善保护自己的个人信息，仅在必要的情况下向第三方提供。',
                '本隐私政策中所述的“手机云管家”及相关服务有可能会根据你所使用的手机型号、系统版本、软件应用程序版本等因素而有所不同。最终的产品和服务以你所使用的“手机云管家”软件及相关服务为准。',
            ]

        },
        {
            "title":"十一、其他",
            "content":[
                `（一）本“隐私政策”中的标题仅为方便及阅读而设，并不影响本《隐私政策》中任何规定的含义或解释。`,
                `（二）本“隐私政策”相关词语释义：`,
                `1. “手机云管家”软件及相关服务是指公司及其关联方通过合法拥有并运营的、标注名称为“手机云管家”的客户端应用程序以及“手机云管家”官方网站，向你提供的产品与服务，包括但不限于个性化拍照和互动等功能。`,
                `2. 关联方是指，指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的法定代表人。“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被依法认定的方式。`,
                `如对本隐私政策内容有任何疑问、意见或建议，您可以通过邮箱：cleanphone.feedback@gmail.com 与我们取得联系，我们会很乐于回答您的问题`,
            ]
        },
    ],
    'en': [
        {
            'topTitle':`Privacy policy`,
        },
        {
            'content':[`OUR COMMITMENT TO YOU`],
        },
        {
            'content':[
                 `At Clean Phone App, your privacy is a top priority. `,
                 `We appreciate that you put your trust in us when you provide us with your information and we do not take this lightly.`,
            ],

        },
        {
            'content':[`We do not compromise with your privacy. We design all of our products and services with your privacy in mind. We involve experts from various fields, including legal, security and others to make sure that no decision is taken without respect for your privacy.`],
        },
        {
            'content':[
              `We strive to be transparent in the way we process your data. `,
              `Because we use many of the same online services you do, we know that insufficient information and overly complicated language are common issues in privacy policies. `,
              `We take the exact opposite approach: we have written our Privacy Policy and related documents in plain language.`,
              `We actually want you to read our policies and understand our privacy practices!`
            ],
        },
        {
            'content':[`We work hard to keep your information secure. We have teams dedicated to keeping your data safe and secure. We constantly update our security practices and invest in our security efforts to enhance the safety of your information.`],
        },
        {
            'title': '1.INTRODUCTION',
            'content':[
                `Welcome to Clean Phone App’ Privacy Policy. `,
                `We appreciate that you trust us with your information and we intend to always keep that trust. This starts with making sure you understand the information we collect, why we collect it, how it is used and your choices regarding your information. This Policy describes our privacy practices in plain language, keeping legal and technical jargon to a minimum.`,
            ]
        },
        {
            'title': '2.WHERE THIS PRIVACY POLICY APPLIES',
            'content':[
                `This Privacy Policy applies to websites, apps and other services operated by Clean Phone App. For simplicity, we refer to all of these as our “services” or “apps” in this Privacy Policy. To make it extra clear, we’ve added links to this Privacy Policy on all applicable services.`,
            ]
        },
        {
            'title': '3.INFORMATION WE COLLECT',
            'content':[
                `t goes without saying, we can’t help you have an awesome user experience without some information about you, such as your email address and password in some of our services. We also collect information generated as you use our services, as well as information from third parties. Your information may be kept in an identifiable format, or in an aggregate format which means that you cannot reasonably be identified from it.`,
                `Information you give us`,
                `You choose to give us certain information when using our services. This includes:`,
                `When you create an account in some of our services, you provide us with your login credentials, such as email address, name and password.`,
                `To use certain features in our services, you may allow us to access your camera or the microphone. Depending on your device, Apple and Android system will ask for your permission before allowing us to access these features.`,
                `Features that allow you to share content about your use of our services with your connections or through your social network. We may also collect your information when you use our app such as when you post or share material, upload data, request further services or make selections using our app, if such functionality is available in the app.`,
                `If you contact our support team, we collect the information you give us when you report a problem, contact or communicate with us.`,
                `From time to time, we may request your opinions, suggestions, or modification and improvement ideas (“Feedback”) through surveys, or other similar requests. Any responses you provide are voluntary, however you agree that any Feedback you provide shall be owned Clean Phone App and may be used by Clean Phone App in our sole discretion.`,
                `Information we receive from others`,
                `In addition to the information you provide us directly, we receive information about you from others, including:`,
                `Social Media`,
                `You may be able to use your Facebook account to login to create an account. When you use this feature, we may receive any of your information you provide or permit to be provided to us via a social network or public forum provider like Facebook. This data may include your use of our app on such public forums and/or social networks, including with whom you share our app. For further information as to how and for what purpose the social network or public forum provider collects, uses and shares your data, as well as how you can set your privacy preferences on their site or apps, please see their privacy policy.`,
                `Other Partners`,
                `• We may receive information about you from our partners, for instance where our ads are published on a partner’s website or platform (in which case they may pass along details on a campaign’s success).`,
                `• If you purchase a subscription in our apps, neither Google nor Apple provide us with any personal information of the subscription purchaser (like name, email or payment information). Only anonymized data gets passed to Clean Phone.`,
                `Information collected when you use our servicesWhen you use our services, we collect information about which features you’ve used, how you’ve used them and the devices you use to access our services. See below for more details:Usage Information`,
                `We collect information about your activity on our services, for instance how you use them (e.g., date and time you logged in, time spent in the app, features you’ve been using, advertising that you click on).`,
                `Movement Data: If you opt-in, some services may collect movement data from your device.`,
                `Device information`,
                `We might collect information from and about the device(s) you use to access our services, `,
                `including:`,
                `geolocation data (in some of our apps), which is any information which indicates the `,
                `geographical position of your device and which may include the time the location was recorded, and may include precise (e.g., GPS-level) location data. Some apps may collect this data when the app is not open or actively in use. This may help us or third parties to customize information, content, or offers to you, for instance.`,
                `information on your wireless and mobile network connection, like your service provider and signal strength.`,
                `hardware and software information such as IP address, device type, app crashes, app version, advertising IDs (such as Google’s AdvertisingID and Apple’s IDFA, both of which are randomly generated numbers that you can reset by going into your device’s settings), browser type, version and language, operating system, time zones, identifiers associated with cookies or other technologies that may uniquely identify your device or browser (e.g., IMEI/UDID and MAC address).`,
            ]
        },
        {
            'title': '4.COOKIES AND OTHER SIMILAR DATA COLLECTION TECHNOLOGIES',
            'content':[
                `As described in Sections 3 and 5 of this Privacy Policy, we sometimes work with third parties to deliver ads or offers. Sometimes we or these third parties do so through a mobile “SDK.” The SDK is a bit of computer code that app developers can include in their apps to enable data to be collected and ads to be shown. We may use this and similar technology to deliver certain advertising through mobile applications and browsers based on data associated with your mobile device, and we may work with third parties who likewise embed their SDKs into our services, for third party advertising. If you’d like to opt-out from having ads tailored to you in this way on your mobile device, please follow the instructions below or in the app (as applicable).`,
                `If you wish to opt out of interest-based tracking through your mobile device, you can do so through your device “settings”, as follows (but please note that the below is for informational purposes only and Apple, Google, or other device platforms may change the manner in which their “opt out” settings work at any time): Please note that if you opt out through these settings, you will continue to receive advertising after you opt-out, but it may not be tailored to your interests. For instance, that advertising may be based on your real-time or “contextual” application usage or general location inferred from IP address.`,
                `Opting Out on iOS Devices Such as iPhones, iPads or iPods`,
                `If you have an Apple device, you can opt out of interest-based advertising by updating to iOS 6.0 or higher, and setting Limit Ad Tracking to ‘ON’. On an iPhone 6 for example, you can do this by clicking on Settings -> General -> Privacy -> Advertising and toggling “Limit Ad Tracking” to ‘ON’. Our systems are designed to respect your choice and not use your personal information to provide interest-based advertising when this setting is ON.`,
                `Opting Out on Android Devices`,
                `If you have an Android device and would like to opt out of interest-based advertising, you may do this as follows:`,
                `Open the Settings app on your device`,
                `Go to “Google”`,
                `Select Ads`,
                `Opt out of interest-based ads`,
                `More information on our use of cookies on our websites can be found in our Cookie Policy.`,
            ]
        },
        {
            'title':'5.HOW WE USE INFORMATION',
            'content':[
                `The main reason we use your information is to deliver and improve our services. Additionally, we may use your information to help keep you safe and to communicate with you. Read on for a more detailed explanation of the various reasons we use your information, together with practical examples.`,
                `To administer your account and provide our services to you`,
                `Deliver our services to you and ensure their optimal functionality and operation.`,
                `Identify, establish and manage your account and our relationship with you.`,
                `Provide you with customer support and respond to your requests.`,
                `Should you provide us with this information, we may use your email address, name, country and language for customized marketing purposes.`,
                `When we collect your precise geolocation data (subject to your consent) (which may be via the device’s cellular, Wi-Fi, Global Positioning System (GPS) networks and/or Bluetooth information), we do so to provide you with our location-related products and services, for example to provide you with forecast and weather alerts for your location, and also to facilitate, measure, or otherwise support advertising by third parties (through our apps or third parties’ apps) that may be related to your location over time.`,
                `Some of our apps may offer you the ability to register and login using your third party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the app you are using, and on the social media provider concerned, but may often include your name, e-mail address, mobile phone number and friends list. If you login using your Facebook account, your e-mail or your phone number then we may receive a unique ID in connection with this login. This is solely used for confirmation purposes and is kept separate from all other online identifiers. We will use the information we receive only for the purposes that are described in this Privacy Policy or that are otherwise made clear to you in the app you are using. Please note that we do not control, and are not responsible for, other uses of your personal information by your third party social media provider. We recommend that you see their privacy policy to understand how they collect, use and share your information, and how you can set your privacy preferences on their sites and apps.`,
                `If you have registered with an app using your social media account details, we may use the information we receive from your social media provider to identify which of your friends are also using our services so that you can connect with them in our app.`,
                `In some services, to ensure a consistent experience across your devices`,
                `Link the various apps and devices you use, so that you can enjoy a consistent experience of our services on all of them. This will allow us to form a single view of your use of our services, so you can easily access your content on multiple devices. For this purpose and better user experience generally, we may use and combine your personal information which we collect across different services used by you.`,
                `To improve our services and develop new ones`,
                `Develop new features and services (for example, we may decide to build a new feature further to requests or feedback received from users).`,
                `Clean Phone uses the Google Analytics tool Firebase in order to carry out analysis on information which is gathered by our apps. Device information and other information which we gather are provided to Google in order to enable them to carry out data analysis. Any information which is provided to Google will become subject to Google’s privacy policy which is located here: `,
                `www.google.com/privacy.html. For further information on how Google’s Firebase analytics tool uses data, please see the site “How Google uses data when you use our partners’ sites or apps”, (located at www.google.com/policies/privacy/partners/).`,
                `Conduct research and analysis of your behaviour about your use of, or interest in, our products, services, or content to improve our services and content (for instance, we may decide to change the look and feel or even substantially modify a given feature based on users’ behaviour). This software may record information such as how often you use the services, what happens within the services, aggregated usage, performance data, app errors and debugging information, and where the apps were downloaded from.`,
                `To communicate with you`,
                `Communicate with you by postal mail, telephone and/or mobile devices (including through in-application advertising) about products or services that may be of interest to you either from us, our parent or subsidiary companies or other third parties. If you do not wish to receive in-application advertising, in some cases you can upgrade to the paid version of the app.`,
                `When you share your information with us via a social network provider like Facebook, we use this to show information on your social network profile (subject to your privacy setting and preferences for such social network) and to allow you to share information about our services with your connections or on your social network profile.`,
                `With your consent (where needed), to send you email marketing in order to provide you with information about services that you may find of interest. You can opt-out of receiving direct marketing communications from us by following the unsubscribe instructions included in our marketing communications. We also send you non-marketing emails or messages, such as those related to transactions, your account, security, or product changes. `,
                `Examples of service-related messages include an email address confirmation/welcome email when you register your account, service availability or modification of key features or functions.`,
                `To serve you relevant offers and ads`,
                `Develop and display content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness. When you are on our services, third-party ad partners may provide you with advertising based upon your interests. These companies may use technologies to collect data about users, including precise device location data, to help them tailor relevant ads and measure ads’ effectiveness. These companies may collect this data through SDKs or from users who view or interact with their advertisements. For the purposes of European law, most of these ad partners are independent controllers of your information, which means that they are responsible for providing and complying with their own policies relating to any personal information they obtain in connection with the services. It also means that if you are a Facebook app user for example – you provided consent to Facebook in your Facebook app to allow them to show you personalized ads inside of our services.`,
                `Verify your eligibility and deliver prizes in connection with contests and sweepstakes and in order to conduct polls and surveys;`,
                `To prevent, detect and fight fraud or other illegal or unauthorized activities`,
                `Perform data analysis to better understand and design countermeasures against these activities`,
                `Retain data related to fraudulent activities to prevent against recurrences`,
                `To ensure legal compliance`,
                `Comply with legal requirements`,
                `Assist law enforcement`,
                `Enforce or exercise our rights, for example our EULA`,
                `To process your information as described above, we rely on the following legal bases:`,
                `Provide our service to you: Most of the time, the reason we process your information is to perform the contract that you have with us. For instance, as you go about using our service, we use your information to maintain your account and provide our cool features to you.`,
                `Legitimate interests: We may use your information where we have legitimate interests to do so. For instance, we analyze users’ behavior on our services to continuously improve our offerings and we process information for administrative, fraud detection and other legal purposes.`,
                `Consent: From time to time, we may ask for your consent to use your information for certain specific reasons. You may withdraw your consent at any time where indicated in the services or by contacting us at the address provided at the end of this Privacy Policy.`,
            ]
        },
        {
            'title':'6.HOW WE SHARE YOUR INFORMATION',
            'content':[
                `Since our goal is to provide the best services we can to you, the main sharing of users’ `,
                `information is with service providers and partners who assist us in operating the services, with other IAC Group companies and, in some cases, legal authorities. Read on for more details about how your information is shared with others.`,
                `With our service providers and partners`,
                `We follow a strict vetting process prior to engaging any service provider or working with any partner. We use third parties to help us operate and improve our services. These third parties assist us with various tasks, including data hosting and maintenance, analytics, marketing, advertising and security operations. These service providers may have limited access to your information, which is needed to perform their functions, but are not permitted to share or use such information for any other purposes. For example, we use your non-personal information (meaning information that, by itself, does not identify who you are such as device information) for targeted online marketing through tools like Facebook Custom Audience to contact interested parties.`,
                `In addition, we may share aggregated information we collect, under any of the above circumstances, with third parties, including advisors, advertisers and investors, for the purpose of conducting general business analysis or other legitimate business purposes. Please note that such aggregated information will not include any information which allows third parties to identity you.`,
                `We might share your information with other companies who:`,
                `assist us in technical processing operations, such as data hosting and maintenance, finance, legal, HR and accounting assistance, securing our data and systems and fighting against spam, abuse, fraud, infringement and other wrongdoings.`,
                `• for legitimate business purposes such as corporate audit, analysis and consolidated reporting as well as compliance with applicable laws.`,
                `For corporate transactions`,
                `We may transfer your information if we are involved, whether in whole or in part, in a merger, sale, acquisition, divestiture, restructuring, reorganization, dissolution, bankruptcy or other change of ownership or control.`,
                `When required by law`,
                `We may disclose your information if reasonably necessary: (i) to comply with a legal process, `,
                `We may also share information: (i) if disclosure would mitigate our liability in an actual or threatened lawsuit; (ii) as necessary to protect our legal rights and legal rights of our users, business partners or other interested parties and/or to comply with applicable law; (iii) to enforce our agreements or policies with you; and (iv) to investigate, prevent, or take other `,
                `action regarding illegal activity, suspected fraud or other wrongdoing.`,
                `With your consent or at your request`,
                `We may ask for your consent to share your information with third parties. In any such case, we will make it clear why we want to share the information.`,
            ]
        },
        {
            'title':'7.HOW WE PROTECT YOUR INFORMATION',
            'content':[
                `The security of your personal information is important to us. We follow generally accepted standards to protect the personal information submitted to us, both during transmission and after it is received. We work hard to protect you from unauthorized access to or alteration, disclosure or destruction of your personal information. As with all technology companies, although we take steps to secure your information, we do not promise, and you should not expect, that your personal information will always remain secure. `,
                `We regularly monitor our systems for possible vulnerabilities and attacks and regularly review our information collection, storage and processing practices to update our physical, technical and organizational security measures.`,
                `We may suspend your use of all or part of the services without notice if we suspect or detect any breach of security. If you believe that your account or information is no longer secure,please notify us immediately at cleanphone.feedback@gmail.com`,
            ]
        },
        {
            'title':'8.INFORMATION RELATING TO CHILDREN',
            'content':[
                `Our services are intended for general audiences over the age of 13 years old (or over the age of 16 if you are resident in the EEA). We do not knowingly collect information from children under the age of 13 years old, or 16 years old in the EEA. If you are not over 13 years old (or 16 years old in the EEA) then DO NOT DOWNLOAD OR USE THE SERVICES. If you believe that we may have personal information from or about a child under the age of 13 years old (or over the age of 16 if you are resident in the EEA), please contact us at cleanphone.feedback@gmail.com and we will promptly delete that personal information`,
            ]
        },
        {
            'title':'9.YOUR RIGHTS',
            'content':[
                `We want you to be in control of your information and be aware of your privacy rights, so here are a few key points to remember:`,
                `Depending on your location you may have some or all of the following rights in relation to how we use your personal information:`,
                `• Access: you may request access to your personal information and receive copies of it;`,
                `• Correction: you may have inaccurate/incomplete personal information corrected and updated;`,
                `• Object to, or Limit or Restrict, Use of Data: you can ask us to stop using all or some of your personal information or to limit our use of it.`,
                `• Deletion: in certain circumstances, you can request a right “to be forgotten” (this is a right to have your information deleted or our use of your data restricted). We will honour such requests unless we have to retain this information to comply with a legal obligation or unless we have an overriding interest to retain it.`,
                `• Portability: in certain circumstances, exercise the right to data portability (this is a right to obtain a transferable version of your personal information to transfer to another provider)`,
                `• Consent Management: where we rely on consent to process your personal data, you may withdraw consent at any time. You do not have to provide a reason for your withdrawal where processing is based on consent.`,
                `If you wish to exercise any of these rights, or wish to object to our use of your information, please write to us at the address listed below and we will consider your request in accordance with applicable laws.`,
                `Keep in mind, we may reject requests for certain reasons, including if the request is unlawful or if it may infringe on the rights and freedoms of another person. For example, we may not be able to accommodate certain requests to object to the processing of personal information, notably where such requests would not allow us to provide our service to you anymore.`,
                `For your protection, we may ask you to provide proof of identity before we can answer the above requests.`,
            ]
        },
        {
            'title':'10.CROSS-BORDER DATA TRANSFERS',
            'content':[
                `Sharing of information sometimes involves cross-border data transfers, for instance to the United States of America and other jurisdictions. The United States, European Economic Area (“EEA”) Member States, and other countries all have different laws. When your information is moved from your home country to another country, the laws and rules that protect your personal information in the country to which your information is transferred may be different from those in the country in which you live.`,
                `The European Commission has adopted standard contractual clauses (also known as Model Clauses), which provide safeguards for personal information that is transferred outside of the EEA. We use these Model Clauses or other suitable safeguards (such as EU-US Privacy Shield to send personal information to some of our third party service providers in the United States, where they are certified to receive such information under the Privacy Shield Program) to permit data transfers from the EEA to other countries.`,
            ]
        },
        {
            'title':'11.HOW LONG WE STORE YOUR INFORMATION',
            'content':[
                `We retain your information only for as long as is necessary for the purposes set out in this policy, `,
                `for as long as your account is active or as needed to provide the services to you. We will also `,
                `retain and use your information to the extent necessary:`,
                `to comply with applicable law;`,
                `to evidence our compliance with applicable law;`,
                `if there is an outstanding issue, claim or dispute requiring us to keep the relevant information until it is resolved; or`,
                `if the information must be kept for our legitimate business interests, such as enforcing our terms `,
                `or fraud prevention. For example, information may need to be kept to prevent a user who was `,
                `banned for security incidents from opening a new account.`,
                `Keep in mind that even though our systems are designed to carry out data deletion processes `,
                `according to the above guidelines, we cannot promise that all data will be deleted within a `,
                `specific timeframe due to technical constraints.`,

            ]
        },
        {
            'title':'12.PRIVACY POLICY CHANGES',
            'content':[
                `Because we’re always looking for new and innovative ways to help you enjoy our services, this policy may change over time. We will notify you of any material changes so that you have time to review the changes.`,
            ]
        },
        {
            'title':'13.DATA PROTECTION OFFICER',
            'content':[
                `For our European users, our Data Protection Officer is responsible for ensuring your rights are respected and to review and oversee how we collect and use your personal information. They can be reached by contacting sharpforksltd@gmail.com`,
            ]
        },
        {
            'title':'14.HOW TO CONTACT US',
            'content':[
                `If you have questions about this Privacy Policy, here’s how you can reach us:`,
                `cleanphone.feedback@gmail.com`,
            ]
        },
    ]
};

export { privacyData };
